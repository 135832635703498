<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">
          #Kitakerja
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            :src="imgUrl"
            fluid
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Reset password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Verifikasi Email 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Pada halaman ini Konfirmasi email dilakukan, Anda harus mengakses halaman ini menggunakan alamat email yang valid.
            <br /><br/>
            Apabila ada kendala dalam proses verifikasi email, silahkan hubungi Administrator.
          </b-card-text>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login-v2'}">
              <feather-icon icon="ChevronLeftIcon" /> Kembali ke halaman login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Reset password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@/views/layouts/components/Logo.vue'
import {
  BRow, BCol, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton, BImg,
} from 'bootstrap-vue'
import {
  verify,
} from '@/api/user'
import { required } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    BImg,
    BLink,
  },
  data() {
    return {
      sideImg: require('@/assets/images/pages/reset-password-v2.svg'),
      // validation
      required,
      localdata: {
        token: '',
      },
    }
  },
  async mounted() {
    this.localdata.token = this.$route.query.token
    const response = await verify(this.localdata)
    if (response.data.success === true) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: response.data.message,
          icon: 'EditIcon',
          variant: 'success',
        },
      })
      // this.$router.push({ name: 'penggunaan' })
    } else {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: response.data.message,
          icon: 'EditIcon',
          variant: 'danger',
        },
      })
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/reset-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
